import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VContainer,
        {
          key: _vm.counter,
          attrs: { id: "interest-table", fluid: "", tag: "section" },
        },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: { inline: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "heading",
                  fn: function () {
                    return [_c(VIcon, [_vm._v("mdi-database-edit")])]
                  },
                  proxy: true,
                },
                {
                  key: "after-heading",
                  fn: function () {
                    return [
                      _c("h1", { staticClass: "h5" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("record-edit")))]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                VForm,
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.apply_filters()
                    },
                  },
                },
                [
                  _c(
                    VRow,
                    {
                      staticClass: "my-4",
                      attrs: { align: "center", "no-gutters": "" },
                    },
                    [
                      _c(VTextField, {
                        staticClass: "mr-2",
                        attrs: {
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          label: _vm.$t("who-was-edited"),
                        },
                        model: {
                          value: _vm.edited_filter,
                          callback: function ($$v) {
                            _vm.edited_filter = $$v
                          },
                          expression: "edited_filter",
                        },
                      }),
                      _c(VTextField, {
                        staticClass: "mx-2",
                        attrs: {
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          label: _vm.$t("who-edited"),
                        },
                        model: {
                          value: _vm.edit_filter,
                          callback: function ($$v) {
                            _vm.edit_filter = $$v
                          },
                          expression: "edit_filter",
                        },
                      }),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa-btn-secondary mx-2",
                          on: { click: _vm.clean_filters },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("clean_filters")) + " ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa-btn-primary ml-2",
                          attrs: { type: "submit" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("apply")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c(VSimpleTable, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("who-was-edited"))),
                            ]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("who-edited")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("date-hour")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("details")))]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.records, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _vm._v(_vm._s(item.nameOfWhoWasEdited)),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.nameOfWhomEdited))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm._f("format")(item.editDate))),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "secondary", small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDialog(item)
                                        },
                                      },
                                    },
                                    [_c(VIcon, [_vm._v("mdi-eye")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" - "),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _c(
                        VDialog,
                        {
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [
                          _c("ViewChangesModal", {
                            key: _vm.key,
                            attrs: { recorder: _vm.item_prop },
                            on: { closeModal: _vm.close },
                          }),
                        ],
                        1
                      ),
                      _c(VPagination, {
                        attrs: { length: _vm.total_pages, color: "secondary" },
                        on: { input: _vm.change_page },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    VContainer,
                    [
                      _c(VProgressCircular, {
                        staticStyle: { "margin-left": "50%" },
                        attrs: {
                          indeterminate: "",
                          size: "70",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }