import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "column",
      },
    },
    [
      _c(
        VCard,
        {
          staticStyle: {
            "background-color": "var(--dark)",
            "border-bottom-left-radius": "0",
            "border-bottom-right-radius": "0",
            "border-top-left-radius": "10px",
            "border-top-right-radius": "10px",
          },
          attrs: { width: "850px", "max-width": "95%" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "h5 pa-5",
              staticStyle: {
                color: "var(--white)",
                display: "flex",
                "justify-content": "center",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.$tc("edited_field", 2)))])]
          ),
        ]
      ),
      _c(
        VCard,
        {
          key: _vm.pluss,
          staticStyle: {
            "overflow-y": "scroll",
            "border-top-left-radius": "0",
            "border-top-right-radius": "0",
          },
          attrs: { width: "850px", "max-width": "95%", "max-height": "500px" },
        },
        [
          _c(
            VRow,
            { staticClass: "ma-0" },
            [
              _c(
                VCol,
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("before-edit")))]
                  ),
                ]
              ),
              _c(
                VCol,
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("after-edit")))]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._l(this.beforeEdit, function (item, name) {
            return _c(
              VRow,
              { key: name, staticClass: "ma-0" },
              [
                item != _vm.afterEdit[name] &&
                !_vm._.isEqual(item, _vm.afterEdit[name])
                  ? _c(VCol, { attrs: { cols: "6" } }, [
                      _c("ul", [
                        _c("li", [
                          _c("b", [_vm._v(_vm._s(name))]),
                          _vm._v(
                            ": " +
                              _vm._s(
                                item == null || item == ""
                                  ? _vm.$t("no-register")
                                  : item
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                item != _vm.afterEdit[name] &&
                !_vm._.isEqual(item, _vm.afterEdit[name])
                  ? _c(VCol, { attrs: { cols: "6" } }, [
                      _c("ul", [
                        _c("li", [
                          _c("b", [_vm._v(_vm._s(name))]),
                          _vm._v(
                            ": " +
                              _vm._s(
                                _vm.afterEdit[name] == null ||
                                  _vm.afterEdit[name] == ""
                                  ? _vm.$t("no-register")
                                  : _vm.afterEdit[name]
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }